import React, { useEffect } from 'react';

import map from 'lodash/map';
import { v4 as uuid } from 'uuid';

import { Common } from 'components/shared';
import { promoPanelData } from 'staticContent/promoPanels';
import { scrollToTop } from 'helpers/scrollToTop';
import {
  Footer,
  LoginHeader,
  PromoPanel,
  RecommendedProducts
} from 'components/widgets';

import { useStore } from '../../hooks/useStore';
import { LoginPanel } from '../../widgets';

import S from './styles';

const { H2 } = Common
const {
  LoginPageWrapper,
  Wrapper,
  Promo,
  Background
} = S

export const LoginPage = () => {
  const { view: { activeSidebar } } = useStore()
  useEffect(() => scrollToTop(), [])
  return (
    <LoginPageWrapper
      data-test="login-page"
      sidebarActive={activeSidebar}
    >
      <LoginHeader />
      <Wrapper>
        <Background activeSidebar={activeSidebar}>
          <LoginPanel />
        </Background>
        <Promo>
          {map(promoPanelData, ({ Img, title, description }) => (
            <PromoPanel
              key={uuid()}
              Img={Img}
              title={title}
              description={description}
            />))}
        </Promo>
        <H2>Рекомендуемые продукты</H2>
        <RecommendedProducts />
      </Wrapper>
      <Footer />
    </LoginPageWrapper>
  );
};
